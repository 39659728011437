import { useRouter } from 'next/router';

export default function HomePage() {
  const router = useRouter();

  if (typeof window !== 'undefined') {
    router.replace('/card');
  }

  return null;
}
